.container {
  padding: 1vw 8vw;
}
.content {
  display: flex;
  /* background-color: pink; */
  justify-content: space-between;
  margin-top: 4vw;
}
.left {
  display: flex;
  flex-direction: column;
  width: 30vw;
  border: 0.1vw solid black;
  border-radius: 1vw;
  padding: 1.5vw;
  align-self: center;
  box-shadow: 1vw 1vw 3vw grey;
}
.heading {
  font-size: 2vw;
  font-weight: bold;
  align-self: center;
}
.lableNameWrap {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  margin-top: 1vw;
}
.label {
  font-size: 1.5vw;
  font-weight: 600;
}
.inputfield {
  font-size: 1vw;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  border-style: solid;
  border-color: black;
  border-width: 0.1vw;
}
.btn {
  margin-top: 1.5vw;
  font-size: 1.5vw;
  border: none;
  border-radius: 0.5vw;
  background-color: black;
  color: white;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  cursor: pointer;
}
.right {
  width: 40vw;
  justify-content: end;
  align-items: center;
  display: flex;
}
.faceLogo {
  width: 70%;

  /* justify-self: right; */
}

@media (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 10vw;
  }
  .right {
    transform: scale(0);
  }
  .left {
    display: flex;
    flex-direction: column;
    width: 60vw;
    border: 0.1vw solid black;
    border-radius: 1vw;
    padding: 4vw;
    box-sizing: border-box;
    align-self: center;
    box-shadow: 1vw 1vw 3vw grey;
  }
  .heading {
    font-size: 4vw;
    font-weight: bold;
    align-self: center;
  }
  .lableNameWrap {
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
    margin-top: 1vw;
  }
  .label {
    font-size: 3vw;
    font-weight: 600;
    margin-top: 2vw;
  }
  .inputfield {
    font-size: 3vw;
    box-sizing: border-box;
    padding: 1vw 1.5vw;
    border-radius: 0.5vw;
    border-style: solid;
    border-color: black;
  }
  .btn {
    margin-top: 4vw;
    font-size: 3vw;
    border: none;
    border-radius: 0.5vw;
    background-color: black;
    color: white;
    box-sizing: border-box;
    padding: 1.5vw 0vw;
    cursor: pointer;
  }
}
