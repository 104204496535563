.header {
  display: flex;
  justify-content: space-between;
  /* background-color: yellow; */
}
.apolloLogo{
    height: 7vw;
}
.aivotLogo{
  height: 6vw;
  align-self: center;
}

@media (max-width: 768px) {
  .apolloLogo{
    height: 12vw;
}
.aivotLogo{
  height: 12vw;
  align-self: center;
}
}