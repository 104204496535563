.container {
  padding: 1vw 8vw;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 0 4vw 0 4vw
  /* background-color: yellow; */
}
.apolloLogo{
    height: 7vw;
}
.aivotLogo{
  height: 6vw;
  align-self: center;
}
.content {
  display: flex;
  /* background-color: pink; */
  justify-content: space-between;
  margin-top: 4vw;
}
.left {
  width: 35vw;
  align-self: center;
}
.right {
  width: 40vw;
  justify-content: end;
  /* background-color: yellow; */
  display: flex;
}
.heading {
  font-size: 3vw;
  font-weight: 700;
  /* background-color: yellow; */
  line-height: 4vw;
}
.description{
  font-size: 1.5vw;
  margin-top: 2vw;
  line-height: 2vw;
}
.btnSec {
  display: flex;
  margin-top: 5vw;
  justify-content: space-between;
}
.btn{
  padding: 1vw 2vw 1vw 2vw;
  border-radius: 1.5vw;
  width: 45%;
  border: none;
  background-color: black;
  color: white;
  font-size: 1.5vw;
  cursor: pointer;
}
.faceLogo{
  width: 70%;
  /* justify-self: right; */
}

@media (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
    /* background-color: pink; */
    justify-content: center;
    align-items: center;
    margin-top: 6vw;
  }
  .left {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
  }
  .right {
    transform: scale(0);
  }
  .heading {
    font-size: 6vw;
    font-weight: 700;
    line-height: 7vw;
    text-align: center;
  }
  .description{
    font-size: 4vw;
    margin-top: 5vw;
    line-height: 5vw;
    text-align: center;
  }
  .btnSec {
    display: flex;
    flex-direction: column;
    margin-top: 5vw;
    justify-content: space-between;
    align-items: center;
  }
  .btn{
    margin: 2vw;
    padding: 2vw 4vw;
    border-radius: 5vw;
    width: 50vw;
    border: none;
    background-color: black;
    color: white;
    font-size: 4vw;
    cursor: pointer;
  }
}