.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.verticalHalfPage {
  width: 50%;
  height: 100%;
}

.leftPartOfPage {
  background-color: #f0f0f0;
}

.rightPartOfPage {
}

.logoRow {
  height: 20%;
}

.apolloLogo {
  height: 7vw;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1vw;
  margin-left: 7vw;
}

.aivotLogo {
  height: 6vw;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1vw;
  margin-right: 7vw;
}

.midRow {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.midRow > img {
  height: calc(min(80%, 41vw));
  max-width: 43vw;
  aspect-ratio: 1.1/1;
}

.lastRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
}

.content {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headText {
  text-align: center;
  font-size: 2.5vw;
  font-weight: bold;
  margin-bottom: 2vh;
}

.explText {
  text-align: center;
  font-size: 1.25vw;
  color: rgb(40, 40, 40, 0.7);
}

.form {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.form > * {
  margin-bottom: 2vh;
}

.form > .headText {
  margin-bottom: 3vh;
}

.formTextLabel {
  font-size: 1.4vw;
  color: rgb(40, 40, 40, 0.8);
}

.formTextInput {
  width: 27vw;
  box-sizing: border-box;
  padding: 0.8vw 1vw;
  border: 0.2vw solid rgb(40, 40, 40, 0.8);
  border-radius: 2vw;
  font-size: 1.25vw;
  color: rgb(40, 40, 40, 0.7);
  margin-bottom: 3.5vh;
}

.form > div {
  text-align: left;
  padding-left: 1.5vw;
  width: 100%;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.btn {
  font-size: 1.5vw;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 2vw;
  padding: 1.2vw 0;
  width: 27vw;
  margin-bottom: 3vh;
  margin-top: 1vh;
  cursor: pointer;
}

.btnDisabled {
  font-size: 1.5vw;
  background-color: #555;
  color: white;
  border: 1px solid black;
  border-radius: 2vw;
  padding: 1.2vw 0;
  width: 27vw;
  margin-bottom: 3vh;
  margin-top: 1vh;
  cursor:progress;
  pointer-events: none;
}

.btnWrapper > a {
  text-decoration: none;
  color: black;
  font-size: 1.25vw;
  color: rgb(40, 40, 40, 0.7);
}

@media (max-width: 768px) {
	.leftPartOfPage {
		position: absolute;
		left: -100%;
	}
	.rightPartOfPage {
		width: 100%;
	}
	
	.midRow {
		height: fit-content;
	}

	.apolloLogo {
		position: fixed;
		left: 0;
		top: 0;
		height: 12vw;
	}

	.aivotLogo {
		height: 12vw;
	}
	
	.btn, .formTextInput {
		width: 60vw;
		height: 12vw;
		margin-bottom: 3vh;
		border-radius: 3vh;
	}

	.form {
		height: fit-content;
	}

	.headText {
		font-size: 5vw;
	}

	.btn {
		font-size: 1.7vh;
	}

	.formTextLabel {
		font-size: 4vw;
	}

	.formTextInput {
		font-size: 3vw;
		padding-left: 3vw;
	}


}
